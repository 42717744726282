<template>
  <div
    class="post-creation"
    :class="mq.phone ? 'phone-post-creation' : ''"
  >
    <div
      class="post-creation-header"
      @keydown.exact.enter.stop="enterPress"
    >
      <div class="avatar-name">
        <!-- User avatar -->
        <img
          class="avatar"
          :src="userPicture"
          alt="user avatar"
        >
        <div class="user-name">
          {{ userName }}
        </div>
      </div>
    </div>

    <!-- Post content -->
    <textarea
      v-model="inputText"
      rows="1"
      class="user-input"
      :placeholder="$t('CreatePostModal.messagePlaceHolder')"
      @input="mixin_autoResize_resize"
      @focus="displayFields = true"
    />

    <!-- Attached files -->
    <div
      v-if="nonCadycoAttachedFiles.length > 0"
      class="files"
    >
      <AttachedFiles
        :initial-attached-files="nonCadycoAttachedFiles"
        :read-only="false"
        class="attached-file"
        @removeAttachedFile="removeAttachedFile"
      />
    </div>

    <!-- Cadyco attached file -->
    <div
      v-if="hasAttachedCadycoFile"
      class="cadyco-attached-file"
    >
      <Map
        v-if="attachedCadycoFileData !== undefined"
        class="media"
        :is-edit-mode="true"
        :object-list="[attachedCadycoFileData]"
        @removeFile="removeCadycoFile"
        @click.stop
      />
    </div>

    <!-- TODO : indicator -->

    <!-- Add attached file -->
    <div
      v-if="displayFields && attachedFiles.length === 0"
      class="add-document"
      @click="isFilePickerModalDisplayed = true"
    >
      <img
        class="picture"
        src="@/assets/socialnetwork_sharedoc.png"
        alt="Share doc"
      >
      <p
        v-t="'CreatePostModal.addPortFolioDocument'"
        class="label"
      />
    </div>

    <div class="footer">
      <!-- Group selection button-->
      <Dropdown
        v-if="displayFields"
        v-model="selectedGroup"
        :list="groupsToPost"
        list-max-width="300px"
        display-field="name"
        class="group-selection"
        @click.stop
      />

      <Button
        v-if="displayFields"
        class="submitButton"
        :label="$t('CreatePostModal.submitButton')"
        @click="createPost"
      />
    </div>

    <teleport to="body">
      <FilePickerModal
        v-if="isFilePickerModalDisplayed"
        height="20em"
        class="file-picker"
        :multi-selection="false"
        @addedFiles="updateAttachedFiles"
        @close="isFilePickerModalDisplayed = false"
        @click.stop
      />
    </teleport>
    <Spinner
      v-if="isSpinnerDisplayed"
      style="z-index: 10000"
    />
  </div>
</template>

<script>
import socialNetwork from '@/api/socialNetwork/socialNetwork.service'
import media from '@/api/documents/media.service'
import FilePickerModal from '@components/FilePicker/FilePickerModal'
import Map from '@components/Map/Map'
import communities from '@/api/communities/communities.service'
import AttachedFiles from '@components/Base/AttachedFiles'

export default {
  name: 'DashBoardCreatePost',
  components: { AttachedFiles, Map, FilePickerModal },
  inject: ['mq'],
  emits: ['postCreated'],
  data () {
    return {
      isSpinnerDisplayed: false,
      inputText: '',
      cadycoOrg: { name: 'CaDyCo', id: '-1' },
      groupList: [
        { name: 'all', label: this.$t('CreatePostModal.groups.all') }
        // { name: 'me', label: this.$t('CreatePostModal.groups.me') }
      ],
      selectedGroup: undefined,
      isFilePickerModalDisplayed: false,
      attachedFiles: [],
      attachedCadycoFile: undefined,
      attachedCadycoFileData: undefined,
      userCommunities: [],
      displayFields: false
    }
  },
  computed: {
    userFirstName () {
      return (this.user !== undefined) ? this.user.firstName : ''
    },
    userName () {
      return (this.user !== undefined) ? this.user.firstName + ' ' + this.user.lastName : ''
    },
    userPicture () {
      return (this.user !== undefined) ? this.user.picture : ''
    },
    user () {
      return this.$store.state.auth.user
    },
    groupsToPost () {
      return [this.cadycoOrg, ...this.userCommunities]
    },
    isEmptyContent () {
      return this.inputText.trim() === ''
    },
    hasAttachedCadycoFile () {
      for (const attachedFile of this.attachedFiles) {
        if (attachedFile.extension === 'cao') {
          return true
        }
      }
      return false
    },
    nonCadycoAttachedFiles () {
      // Returns the initial attached files
      // or in case of editMode, the modified attached files
      const nonCadycoAttachedFiles = []
      for (const attachedFile of this.attachedFiles) {
        if (attachedFile.extension !== 'cao') {
          nonCadycoAttachedFiles.push(attachedFile)
        }
      }
      return nonCadycoAttachedFiles
    }

  },
  created () {
    this.getUserCommunities()
    this.selectedGroup = this.cadycoOrg
  },
  mounted () {
    this.initInput()
    window.addEventListener('click', this.clickOutside)
    window.addEventListener('contextmenu', this.clickOutside)
  },
  beforeUnmount () {
    window.removeEventListener('click', this.clickOutside)
    window.removeEventListener('contextmenu', this.clickOutside)
  },
  methods: {
    clickOutside (e) {
      if (this.inputText === '' && this.attachedFiles.length === 0) {
        const self = this
        if (self.$el && !self.$el.contains(e.target)) {
          this.displayFields = false
        }
      }
    },
    enterPress () {
      if (this.inputText.length > 0) {
        this.createPost()
      }
    },
    createPost () {
      if (!this.isEmptyContent) {
        let attachedFileId = 0
        if (this.attachedFiles.length > 0) {
          attachedFileId = this.attachedFiles[0].id
        }

        this.isSpinnerDisplayed = true
        socialNetwork.createBlog(this.inputText, attachedFileId, 0, this.selectedGroup.id).then((data) => { // TODO sanitizer?
          this.isSpinnerDisplayed = false

          if (data.success) {
            this.$store.dispatch('popups/pushPopup', { message: this.$t('Post.createdPostPopup') })
            this.inputText = ''
            this.attachedFiles = []
            this.attachedCadycoFile = undefined
            this.initInput()
            data.createdBlog.attachedFiles.forEach(function (file) { file.type = 'File' })
            this.$emit('postCreated', data.createdBlog)
          } else {
            console.error('Sorry but unknown error was thrown')
          }
        })
      } else {
        this.$store.dispatch('popups/pushPopup', { message: 'Un post ne doit pas avoir un message vide' })
      }
    },
    getUserCommunities () {
      communities.getUserCommunities().then((data) => {
        if (data.success) {
          this.userCommunities = data.communityList
        } else {
          console.error('Error while getting user\'s communities', data.error)
        }
      })
    },
    updateAttachedFiles (selectedFiles) {
      this.attachedFiles = selectedFiles
      // Parse files to fetch if 1 cao
      this.attachedCadycoFileData = undefined
      for (const attachedFile of this.attachedFiles) {
        if (attachedFile.extension === 'cao') {
          this.loadCadycoFileData(selectedFiles[0].id)
        }
      }
    },
    loadCadycoFileData (fileId) {
      media.getCadycoFileData(fileId).then((data) => {
        if (data.success) {
          this.attachedCadycoFileData = data.cadycoFileData
        } else {
          console.error('Error when getting cadyco file data')
        }
      })
    },
    removeCadycoFile () {
      this.removeAttachedFile()
      this.attachedCadycoFileData = undefined
    },
    removeAttachedFile () {
      this.attachedFiles = []
    },
    mixin_autoResize_resize (event) {
      event.target.style.height = 'auto'
      event.target.style.height = `${event.target.scrollHeight}px`
    },
    initInput () {
      this.$nextTick(() => {
        this.$el.setAttribute('style', 'height', `${this.$el.scrollHeight}px`)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@design';

.post-creation {
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.08);

.post-creation-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;

    .avatar-name {
      display: flex;
      align-items: center;
      .avatar {
        border-radius: 50px;
        width: 38px;
        height: 38px;
        margin-right: 10px;
      }
      .user-name {
        color: $color-cadyco-dark-text;
        font-weight: bold;
      }
    }
  }

  .user-input {
    min-width: 275px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    width: 95%;
    padding: 10px 10px;
    line-height: 10px;
    resize: none;
    border: none;
    border-radius: 6px;
    background-color: $dark-white-bg;
    outline-color: $public-bg;
  }

  .add-document {
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 30px;

    &:hover {
      color: $color-dark-text;
      cursor: pointer;
    }
    .picture {
      max-width: 20px;
      max-height: 20px;
      margin-right: 10px;
    }
  }

  .files {
    margin-top: 15px;
    display: flex;
    align-items: center;

    .attached-file{
      width: 100%;
    }
  }

  .cadyco-attached-file {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .media {
      width: 500px;
      height: 400px;
      margin-bottom: 15px;
    }

    .remove-media {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 25px;
      cursor: pointer;
    }
  }

  .footer {
    width: 100%;
    display: flex;
    margin-bottom: 10px;

    .group-selection {
      margin-left: auto;
      height: 30px;
    }

    .submitButton {
      margin-right: 20px;
      margin-left: 20px;
      background-color: $public-bg;
      color: white !important;
    }
  }

}

.phone-post-creation {
  width: 90vw;

  .user-input {
    width: 90%;
  }
}

</style>
