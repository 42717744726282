<template>
  <div v-if="!isAuthenticated">
    <PublicHome />
  </div>
  <div v-else>
    <Dashboard />
  </div>
</template>

<script>
import PublicHome from '@/router/views/PublicHome.vue'
import Dashboard from '@/router/views/Dashboard.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: {
    PublicHome,
    Dashboard
  },
  computed: {
    ...mapGetters('auth', [
      'isAuthenticated'
    ])
  },
  mounted () {
    if (this.isAuthenticated) {
      this.$router.push({ name: 'dashboard' })
    }
  }
}
</script>

<style lang="scss" scoped>

.body {
  background-color: #27AAE1;
  height: 1400px;
  width: 100%;
  z-index: 0;
  .cadyco-presentation {

    .img-intro {
      height: 800px;
      z-index: 10;
    }

    .cadyco-explanation {
      position: absolute;
      top: 300px; left: 550px;
      height: 150px;
      width: 303px;
      color: #FFFFFF;
      font-size: 40px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 50px;
      p {
        color: #FFFFFF;
        span {
          color: #0B3C5F;
        }
      }
    }

    .project {
      position: absolute;
      top: 200px; left: 800px;
      padding-left: 40px;
      padding-top: 40px;
      margin: 20px;
      width: 500px;

      p {
        color: #FFFFFF;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 27px;
        margin-bottom: 20px;
      }
      .bold {
        font-weight: bold
      }
    }
  }

  .examples {
    position: absolute;
    top: 700px; left: 150px;
    width: 900px;

    p {
      height: 48px;
      width: 1276px;
      color: #FFFFFF;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 48px;
      text-align: center;
      margin-bottom: 20px;
    }

    .title {
      font-size: 32px;
    }
    .indicators {
      margin: auto;
    }
  }
}
</style>
