<template>
  <div class="post-header">
    <div class="user-date">
      <img
        class="picture"
        :src="post.author.picture"
        alt="user avatar"
      >

      <!-- User name and post date -->
      <div class="user-name-date">
        <div class="user-name">
          {{ post.author.fullName }}
        </div>
        <div class="post-date">
          {{ formattedPostDate }}
        </div>
      </div>
    </div>

    <!-- Modified post label -->
    <div
      v-if="isModified && !mq.phone"
      v-t="'Post.modified'"
      class="is-modified"
    />

    <!-- Target community -->
    <div
      v-if="!mq.phone"
      class="target-community"
    >
      <img
        src="@assets/icon_commu-black.svg"
        alt=""
      >
      <div class="community-name">
        {{ post.sourceCommunity.name }}
      </div>
    </div>

    <!-- Action icons -->
    <div class="action-icons">
      <!-- Edit post if user is the owner -->
      <div
        v-if="hasEditPermission"
        class="action-icon edit"
        :title="$t('Post.edit')"
        @click="editPost"
      >
        <img
          src="@/assets/options/icon_edit_texte.svg"
          :alt="$t('Post.edit')"
        >
      </div>

      <!-- Delete post if user is the owner -->
      <div
        v-if="hasDeletePermission"
        class="action-icon"
        :title="$t('Post.delete')"
        @click="displayDeletePostConfirmModal"
      >
        <img
          src="@/assets/options/icon_trash.svg"
          :alt="$t('Post.delete')"
        >
      </div>
    </div>
  </div>
</template>

<script>
import utils from '@utils/utils'
import contextMenus from '@utils/contextMenus'
import moment from 'moment'

export default {
  name: 'PostHeader',
  inject: ['mq'],
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  emits: ['editPost', 'deletePost'],
  data () {
    return {
      isEditMode: false
    }
  },
  computed: {
    userName () {
      const user = this.$store.state.auth.user
      return (user !== undefined) ? user.firstName + ' ' + user.lastName : ''
    },
    hasAttachedCadycoFile () {
      return (this.post.attachedFiles.length > 0 && this.post.attachedFiles[0].extension === 'cao')
    },
    hasDeletePermission () {
      return this.post.permissions.delete
    },
    hasEditPermission () {
      return this.post.permissions.modify
    },
    isModified () {
      const creationDate = moment(this.post.createDate, 'YYYY-MM-DD HH:mm:ss')
      const modifiedDate = moment(this.post.modifiedDate, 'YYYY-MM-DD HH:mm:ss')
      const duration = moment.duration(modifiedDate.diff(creationDate))
      const differenceInSecond = duration.asSeconds()

      return differenceInSecond >= 10 // Count as modified if modified date in at least 10 second after creation
    },
    contextMenuOptions () {
      const options = utils.cloneArrayOfObject(contextMenus.postOptions)
      if (!this.hasAttachedCadycoFile) {
        utils.removeMenuOptionIfExist(options, 'download')
        utils.removeMenuOptionIfExist(options, 'copyInPortFolio')
      }
      if (!this.post.permissions.delete) {
        utils.removeMenuOptionIfExist(options, 'delete')
      }
      if (!this.post.permissions.modify) {
        utils.removeMenuOptionIfExist(options, 'modify')
      }
      return options
    },
    formattedPostDate () {
      const postDate = moment(this.post.displayDate, 'YYYY-MM-DD HH:mm:ss')
      const now = moment()
      var diffDays = now.diff(postDate, 'days', true)
      if (diffDays > 1) {
        const nbDays = Math.round(diffDays)
        return nbDays + (nbDays > 1 ? ' jours' : ' jour')
      } else {
        var diffHours = now.diff(postDate, 'hours', true)
        if (diffHours > 1) {
          const nbHours = Math.round(diffHours)
          return nbHours + (nbHours > 1 ? ' heures' : ' heure')
        } else {
          var diffMinutes = now.diff(postDate, 'minutes', true)
          const nbMinutes = Math.round(diffMinutes)
          return nbMinutes + (nbMinutes > 1 ? ' minutes' : ' minute')
        }
      }
    }
  },
  methods: {
    editPost () {
      this.isEditMode = true
      this.$emit('editPost')
    },
    deletePost () {
      this.$emit('deletePost')
    },
    displayDeletePostConfirmModal () {
      this.$store.dispatch('warningModal/addWarning', {
        text: this.$t('router.views.Documents.Warning.deleteDocument'),
        lastAction: { fct: this.deletePost, params: [this.post] }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@design';

.post-header {
  width: 100%;
  height: 50px;
  max-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;

  .user-date {
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .picture {
      margin-right: 20px;
      width: 38px;
      height: 38px;
      border-radius: 19px;
    }

    .user-name-date {
      .user-name {
        color: $color-cadyco-dark-text;
        font-weight: bold;
      }
      .post-date {
        float: left;
        font-size: 13px;
        color: #acacac;
      }
    }
  }

  .is-modified {
    margin-left: 15px;
    font-size: 13px;
    color: #acacac;
  }

  .target-community {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 20px;

    .community-name {
      display: inline;
      margin-left: 5px;
      font-size: 0.875em;
      letter-spacing: 0;
      line-height: 21px;
      color: $color-cadyco-dark-text;
    }

    img {
      width: 25px;
    }
  }

  .action-icons {
    display: flex;
    align-items: center;
    padding-right: 10px;

    .action-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 33px;
      height: 33px;
      border-radius: 17px;
      background-color: $dark-white-bg;
      cursor: pointer;

      img{
        width: 15px;
      }

      &:hover {
        background-color: $color-hover-cadyco;
      }
    }

    .edit {
      margin-right: 10px;

      img{
        width: 13px;
      }
    }
  }
}

</style>
