<template>
  <div class="like">
    <!-- Number of likes -->
    <div
      class="nb-likes"
      :class="[post.isLiked ? 'is-liked': '', mq.phone ? 'phone-nb-like' : 'desktop-nb-like']"
      :title="$tc('Post.peopleHavingLiked', nbLikes)"
      @click="toggleLike"
    >
      <img
        v-show="mq.phone || post.isLiked"
        class="thumbs-up"
        src="@/assets/thumbsUp.svg"
        alt="Nombre de likes"
      >
      <img
        v-show="!mq.phone && !post.isLiked"
        class="thumbs-up"
        src="@/assets/thumbsUp_OFF.svg"
        alt="Nombre de likes"
      >
      {{ nbLikes }}
      <div
        v-if="mq.phone"
        class="vertical-line"
      />
      <div
        v-if="mq.phone"
        class="phone-label"
      >
        <img
          v-show="post.isLiked"
          class="thumbs-up"
          src="@/assets/thumbsUp.svg"
          alt="Nombre de likes"
        >
        <img
          v-show="!post.isLiked"
          class="thumbs-up"
          src="@/assets/thumbsUp_OFF.svg"
          alt="Nombre de likes"
        >
        {{ 'C\'est intéressant !' }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Like',
  components: { },
  inject: ['mq'],
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  emits: ['like'],
  computed: {
    nbLikes () {
      return this.post.nbLikes
    }
  },
  methods: {
    toggleLike () {
      if (this.post.isLiked) {
        this.$emit('like', false)
      } else {
        this.$emit('like', true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.like {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;

  &:hover {
    cursor: pointer;
  }

  .nb-likes {
    display: flex;
    align-items: center;
    color: black;

    .thumbs-up {
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }
  }

  .vertical-line {
    width: 1px;
    height: 20px;
    margin: 0 20px;
    background-color: $color-separation-bar;
  }

  .phone-label {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-cadyco-dark-text;
    font-weight: 600;
  }

  .phone-nb-like {
    color: rgb(39, 170, 225);
    margin-bottom: 10px;

    .thumbs-up {
      height: 17px;
      width: 17px;
      margin-right: 5px;
    }

    .phone-label {
      .thumbs-up {
        height: 25px;
        width: 25px;
        margin-right: 10px;
      }
    }
  }

  .is-liked {
    color: rgb(39, 170, 225);

    .phone-label {
      color: rgb(39, 170, 225);
    }
  }

}
</style>
