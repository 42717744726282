<template>
  <div
    class="create-comment"
    :class="mq.phone ? 'phone-create-comment' : ''"
    @keydown.exact.enter.stop="enterPress"
  >
    <div class="user-comment">
      <img
        class="picture"
        :src="userPicture"
        alt="user avatar"
      >
      <textarea
        v-model="inputText"
        rows="1"
        class="user-input comment"
        :placeholder="$t('Post.commentPlaceholder')"
        @input="mixin_autoResize_resize"
      />
    </div>
    <Button
      v-if="inputText.length !== 0"
      class="submitButton"
      :label="$t('Post.submitComment')"
      @click="sendComment"
    />
  </div>
</template>

<script>
import socialNetwork from '@/api/socialNetwork/socialNetwork.service'

export default {
  name: 'CreateComment',
  inject: ['mq'],
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  emits: ['commentCreated'],
  data () {
    return {
      inputText: ''
    }
  },
  computed: {
    user () {
      return this.$store.state.auth.user
    },
    userPicture () {
      return (this.user !== undefined) ? this.user.picture : ''
    }
  },
  mounted () {
    this.initInput()
  },
  methods: {
    enterPress () {
      if (this.inputText.length > 0) {
        this.sendComment()
      }
    },
    sendComment () {
      socialNetwork.commentBlog(this.post, this.inputText).then((data) => { // TODO sanitizer?
        if (data.success) {
          this.inputText = ''
          this.$emit('commentCreated', data.createdComment)
          this.$store.dispatch('popups/pushPopup', { message: this.$t('Post.createdCommentPopup') })
        } else {
          if (data.error === 'DuplicateCommentException') {
            this.$store.dispatch('popups/pushPopup', { message: this.$t('Popup.duplicateCommentException') })
          }
          console.error('Sorry but unknown error was thrown')
        }
      })
    },
    mixin_autoResize_resize (event) {
      event.target.style.height = 'auto'
      event.target.style.height = `${event.target.scrollHeight}px`
    },
    initInput () {
      this.$nextTick(() => {
        this.$el.setAttribute('style', 'height', `${this.$el.scrollHeight}px`)
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@design';

.create-comment {
  padding-top: 10px;
  padding-bottom: 13px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .user-comment {
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 20px;

    .picture {
      border-radius: 50px;
      width: 38px;
      height: 38px;
      margin-right: 10px;
    }

    .user-input {
      margin-left: 20px;
      margin-right: 20px;
      width: 95%;
      padding: 10px 10px;
      line-height: 10px;
      resize: none;
      border: none;
      border-radius: 6px;
      background-color: $dark-white-bg;
    }
  }

  .submitButton {
    margin-right: 20px;
    margin-left: auto;
  }
}

.phone-create-comment {

  .user-comment {
    .picture {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      margin-right: 10px;
    }

    .user-input {
      margin: 0;
    }
  }

}

</style>
