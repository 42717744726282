<template>
  <div class="my-map-container">
    <div class="root-map">
      <l-map
        class="map"
        :zoom="zoom"
        :max-zoom="19"
        :use-global-leaflet="true"
        :center="center"
        :options="mapOptions"
        @update:center="centerUpdate"
        @update:zoom="zoomUpdate"
      >
        <l-tile-layer
          :url="url"
          :attribution="attribution"
        />
        <!--    TODO: change marker color depending on cadyco object type ?-->
        <LCustomMarkerCluster :options="{ animateAddingMarkers: true }">
          <l-marker
            v-for="object in objectList"
            :key="object.id"
            :lat-lng="latitudeLongitude(object.GeoLocalisation)"
          >
            <FormationPopup
              v-if="object.type === 'formation'"
              :formation="object"
            />
            <JobOfferPopup
              v-if="object.type === 'jobOffer'"
              :job-offer="object"
            />
            <EtablissementPopup
              v-if="object.type === 'etablissement'"
              :etablissement="object"
            />
          </l-marker>
        </LCustomMarkerCluster>
      </l-map>
    </div>
    <div
      v-if="isInDashBoard"
      class="options"
      :class="mq.phone ? 'phone-options' : ''"
    >
      <div
        v-if="!isEditMode"
        class="image-container"
        :title="$t('ContextMenuOptions.addToPortfolio')"
        @click="addCadycoFileInPortfolio"
      >
        <img
          src="@/assets/duplicate.png"
          alt="add in portfolio"
        >
      </div>
      <div
        v-else
        class="image-container"
        :title="$t('Map.remove')"
        @click="removeFile"
      >
        <img
          src="@/assets/icon_trash.svg"
          alt="delete icon"
        >
      </div>
    </div>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import { latLng, Icon } from 'leaflet'
import { LMap, LTileLayer, LMarker } from '@vue-leaflet/vue-leaflet'
import LCustomMarkerCluster from '@components/Map/LCustomMarkerCluster'
import FormationPopup from '@components/Map/FormationPopup'
import JobOfferPopup from '@components/Map/JobOfferPopup'
import EtablissementPopup from '@components/Map/EtablissementPopup'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default {
  components: {
    LCustomMarkerCluster,
    JobOfferPopup,
    FormationPopup,
    EtablissementPopup,
    LMap,
    LTileLayer,
    LMarker
  },
  inject: ['mq'],
  props: {
    objectList: {
      type: Array,
      required: true
    },
    isEditMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['removeFile', 'addCadycoFileInPortfolio'],
  data () {
    return {
      zoom: 8,
      center: latLng(46.35312518717163, 6.784678858852737),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      // currentZoom: 11.5,
      // currentCenter: latLng(47.41322, -1.219482),
      mapOptions: {
        zoomSnap: 0.5
      }
    }
  },
  computed: {
    isInDashBoard () {
      return this.$route.name === 'dashboard'
    }
  },
  watch: {
    'objectList' () {
      this.center = this.barycenter(this.objectList)
    }
  },
  mounted () {
    if (this.objectList !== undefined) {
      this.center = this.barycenter(this.objectList)
    }
  },
  methods: {
    addCadycoFileInPortfolio () {
      this.$emit('addCadycoFileInPortfolio')
    },
    barycenter (pointList) {
      if (pointList !== undefined && pointList.length !== 0) {
        let sumLat = 0
        let sumLong = 0
        for (let i = 0; i < pointList.length; ++i) {
          if (pointList[i] !== undefined) {
            sumLat += parseFloat(pointList[i].GeoLocalisation.Latitude)
            sumLong += parseFloat(pointList[i].GeoLocalisation.Longitude)
          }
        }
        return latLng(sumLat / pointList.length, sumLong / pointList.length)
      }
    },
    centerUpdate (center) {
      this.center = center
    },
    latitudeLongitude ({ Latitude, Longitude }) {
      return latLng(Latitude, Longitude)
    },
    zoomUpdate (zoom) {
      this.zoom = zoom
    },
    removeFile () {
      this.$emit('removeFile')
    }
  }
}
</script>

<style scoped lang="scss">
@import "@design";

.my-map-container {
  position: relative;
  width: 100%;
  height: 100%;

  .root-map {
    width: 100%;
    height: 100%;
    display: flex;

    .map {
      font-family: "Titillium Web", sans-serif;
    }

    .remove {
      width: 20px;
      height: 20px;
    }
  }

  .options {
    position: absolute;
    top: 0;
    right: -40px;

    .image-container {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 33px;
      height: 33px;
      border-radius: 17px;
      background-color: $dark-white-bg;
      cursor: pointer;

      img{
        width: 15px;
      }

      &:hover {
        background-color: $color-hover-cadyco;
      }
    }
  }

  .phone-options {
    top: 10px;
    right: 10px;
    z-index: 400;
  }
}

</style>
