<template>
  <PublicLayout>
    <div
      class="container"
      :class="device + '-container'"
    >
      <!-- Image -->
      <img
        src="@/assets/img_intro.png"
        class="img-intro"
        :class="device + '-img-intro'"
        alt="Logo"
      >

      <!-- Introduction -->
      <div
        class="home-title"
        :class="device + '-home-title'"
      >
        <!-- CaDyCo name explanation -->
        <div
          class="cadyco-explanation"
          :class="device + '-cadyco-explanation'"
        >
          <p><span>Ca</span>rtographie</p>
          <p><span class="not-first-text">Dy</span>namique</p>
          <p><span class="not-first-text">Co</span>llaborative</p>
        </div>

        <!-- Project explanation -->
        <div
          v-if="!mq.phone"
          class="project"
          :class="device + '-project'"
        >
          <p>{{ $t('Home.line1') }}</p>
        </div>
      </div>

      <!-- Indicator examples -->
      <div
        class="examples"
        :class="device + '-examples'"
      >
        <div class="indicators-container">
          <div class="indicators">
            <PublicIndicators />
          </div>
        </div>
      </div>

      <!-- Project explanation -->
      <div
        v-if="mq.phone"
        class="project"
        :class="device + '-project'"
      >
        <p>{{ $t('Home.line1') }}</p>

        <!-- Login buttons-->
        <Login
          v-if="mq.phone"
          class="login"
          :justify-content="'center'"
          :longtext="true"
        />
      </div>
    </div>
  </PublicLayout>
</template>

<script>
import PublicLayout from '@/router/layouts/PublicLayout.vue'
import Login from '@components/TopBanner/Login'
import PublicIndicators from '@/components/PublicIndicators.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: {
    Login,
    PublicIndicators,
    PublicLayout
  },
  inject: ['mq'],
  computed: {
    device () {
      if (this.mq.phone) {
        return 'phone'
      } else if (this.mq.projectSizeLimit1) {
        return 'limit1'
      } else if (this.mq.projectSizeLimit2 || this.mq.tablet) {
        return 'limit2'
      } else {
        return 'desktop'
      }
    },
    loginUrl () {
      return `${this.$store.state.auth.loginURL}`
    },
    ...mapGetters('auth', [
      'isAuthenticated'
    ])
  },
  mounted () {
    if (this.isAuthenticated) {
      this.$router.push({ name: 'dashboard' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.container {
  background-color: $public-bg;
  width: 100%;
  overflow-y: auto;

  .img-intro {
    position: absolute;
    height: 250px;
  }

  .phone-img-intro {
    left: -65px;
  }

  .home-title {
    margin-left: 220px;
    min-height: 201px;
    margin-right: 10%;

    .cadyco-explanation {
      color: $color-light-text;
      font-weight: bold;
      letter-spacing: 0;
      display: flex;
      font-size: 2.5em;

      p {
        color: $color-light-text;

        span {
          color: #0B3C5F;
        }

        .not-first-text {
          margin-left: 5px;
        }
      }
    }

    .desktop-cadyco-explanation {
      top: 150px; left: 500px;
      height: 120px;
      width: 303px;
      line-height: 50px;
    }

    .limit2-cadyco-explanation {
      font-size: 2em;
    }

    .desktop-project {

      p {
        font-size: 1.125em;
        line-height: 27px;
      }
    }

    .limit1-project {

      p {
        font-size: 1.075em;
        line-height: 20px;
      }
    }

    .limit2-project {

      p {
        font-size: 1.075em;
        line-height: 20px;
      }
    }
  }

  .phone-home-title {
    margin: 0;
    min-height: 0;

    .phone-cadyco-explanation {
      margin-top: 70px;
      margin-left: 170px;
      display: block;
      height: 149px;
      width: 76px;
      font-size: 1.5em;
      line-height: 30px;

      p {
        .not-first-text {
          margin-left: 0;
        }
      }
    }
  }

  .project {
    p {
      color: $color-light-text;
      font-weight: 300;
      letter-spacing: 0;
      margin: 0;
    }

    .bold {
      font-weight: bold
    }
  }

  .phone-project {
    margin-top: 50px;
    padding: 0 10px;

    p {
      text-align: justify;
      font-size: 0.9375em;
      line-height: 23px;
    }

    .login {
      margin: 20px 0;
    }
  }
}

.phone-container {
  height: 650px;
}

.examples {
  width: 100%;

  .title-container {
    display: flex;
    justify-content: center;
    width: 100%;

    p {
      text-align: center;
      color: $color-light-text;
    }

    .title {
      font-weight: bold;
    }

    a{
      font-weight: bold;
      color: $color-light-text;
    }
  }

  .indicators-container {
    position: relative;
    margin-top: 50px;
    margin-bottom: 100px;
    display: flex;
    justify-content: center;

    .indicators {
      width: 80%;
      height: 100%;
    }
  }
}

.desktop-examples, .limit1-examples, .limit2-examples {
  .title {
    font-size: 2em;
    line-height: 48px;
  }
}

.phone-examples {
  height: 452px;

  .title {
    font-size: 1.5em;
    line-height: 26px;
    margin-bottom: 5px;
  }

  .indicators-container {
    margin-top: 25px;

    .indicators {
      width: 100%;
    }
  }
}

</style>
