<template>
  <l-popup>
    <h4>
      {{ jobOffer.titre }}
    </h4>
    <p> Description de l'offre </p>
    <span> tauxEngagementMin : {{ jobOffer.tauxEngagementMin }}% </span><br>
    <span> tauxEngagementMax : {{ jobOffer.tauxEngagementMax }}% </span><br><br>
    <span> renumerationMin : {{ jobOffer.renumerationMin }} € </span><br>
    <span> renumerationMax : {{ jobOffer.renumerationMax }} €</span><br>
    <span> Lien de l'offre : <a
      :href="jobOffer.url"
      target="_blank"
    > {{ jobOffer.url }} </a> </span><br>
  </l-popup>
</template>

<script>
import { LPopup } from '@vue-leaflet/vue-leaflet'

export default {
  name: 'JobOfferPopup',
  components: {
    LPopup
  },
  props: {
    jobOffer: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
