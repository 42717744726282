<template>
  <div
    class="social"
    :class="mq.phone ? 'phone-social' : ''"
  >
    <PostSocialHeader
      :post="post"
      :is-comment-list-displayed="isCommentListDisplayed"
      @toggleComments="toggleComments"
      @like="updateLike"
    />

    <div
      v-if="isCommentListDisplayed"
      class="comment-list"
    >
      <div class="separator" />
      <Comment
        v-for="(comment, index) in loadedComments"
        :key="index"
        class="comment"
        :parent-post="post"
        :comment="comment"
        @modified="commentUpdated"
        @deleted="commentDeleted(comment)"
      />

      <span
        v-if="stillUnloadedComments"
        class="unfold-more-comments"
        @click="loadComments"
      >
        {{ $t('Post.unfoldMore') }}
      </span>
    </div>

    <div class="separator" />

    <CreateComment
      :post="post"
      class="create-comment"
      @commentCreated="commentCreatedAction"
    />
  </div>
</template>

<script>
import socialNetwork from '@/api/socialNetwork/socialNetwork.service'
import appConstants from '@/constants/appConstants'
import CreateComment from '@components/DashBoard/CreateComment'
import Comment from '@components/DashBoard/Comment'
import PostSocialHeader from '@components/DashBoard/PostSocialHeader'
export default {
  name: 'PostSocial',
  components: { PostSocialHeader, Comment, CreateComment },
  inject: ['mq'],
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  emits: ['commentNb', 'like'],
  data () {
    return {
      isCommentListDisplayed: false,
      loadedComments: []
    }
  },
  computed: {
    stillUnloadedComments () {
      return this.loadedComments.length < this.post.nbComments
    }
  },
  methods: {
    commentCreatedAction (newComment) {
      this.$emit('commentNb', (this.post.nbComments + 1))
      this.loadComments()
      this.isCommentListDisplayed = true
    },
    commentUpdated (updatedComment) {
      for (let i = 0; i < this.loadedComments.length; ++i) {
        if (this.loadedComments[i].commentId === updatedComment.commentId) {
          this.loadedComments.splice(i, 1, updatedComment)
          break
        }
      }
    },
    commentDeleted (deletedComment) {
      this.$emit('commentNb', (this.post.nbComments - 1))
      for (let i = 0; i < this.loadedComments.length; ++i) {
        if (this.loadedComments[i].commentId === deletedComment.commentId) {
          this.loadedComments.splice(i, 1)
          break
        }
      }
    },
    toggleComments () {
      this.isCommentListDisplayed = !this.isCommentListDisplayed
      if (this.isCommentListDisplayed && this.loadedComments.length === 0) {
        this.loadComments()
      }
    },
    loadComments () {
      socialNetwork.getComments(this.post, this.loadedComments.length, this.loadedComments.length + appConstants.nbCommentsToLoadAtOneTime, this.$store.state.auth.user).then((data) => {
        this.loadedComments = [...this.loadedComments, ...data]
      })
    },
    updateLike (value) {
      this.$emit('like', value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@design';

.social{

  .comment-list{

    .comment{
      padding-left: 30px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .unfold-more-comments {
      margin-left: 25px;
      color: $color-link-text;
      cursor: pointer;
    }
  }

  .separator {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 20px;
    margin-right: 20px;
    border-top: 1px solid $color-border-menu;
  }
}

.phone-social {
  .comment-list {

    .comment {
      padding-left: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
</style>
