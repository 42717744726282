<template>
  <l-popup
    class="popup"
    :class="mq.phone ? 'phone-popup' : ''"
  >
    <div
      class="header"
      @click="openFormationDetails"
    >
      <h4>
        {{ formation.Intitule }}
      </h4>
      <img
        class="icon details-icon"
        src="@assets/arrow_right_blue.svg"
        alt="Details"
      >
    </div>
    <span> Type : {{ formation.Type }} </span><br>
    <span> Niveau : {{ formation.Grade }} </span><br>
    <span> Durée : {{ formation.Duree + ' ' + formation.UniteDuree + (formation.Duree > 1 ? 's' : '') }} </span><br>
    <span> Spécialité : {{ formation.Specialisation }} </span><br v-if="formation.PublicsCible.length > 0">
    <span v-if="formation.PublicsCible.length > 0"> Public cible : <span
      v-for="(target, index) in formation.PublicsCible"
      :key="index"
    > {{ target.Nom + ' ' }} </span> </span><br>
    <span> ECTS : {{ formation.ECTS }} </span><br><br>
    <span> Code : {{ formation.Code }} </span><br>
    <span> Site web : <a
      :href="formation.SiteWeb"
      target="_blank"
    > {{ formation.SiteWeb }} </a> </span><br>
    <div
      class="image-container"
    >
      <img
        v-if="formation.etablissementThumbnail"
        class="image"
        :src="formation.etablissementThumbnail"
        alt="img"
      >
      <div
        v-if="bothThumbnails"
        class="separator"
      />
      <img
        v-if="formation.composanteThumbnail"
        class="image"
        :src="formation.composanteThumbnail"
        alt="img"
      >
    </div>
  </l-popup>
</template>

<script>
import { LPopup } from '@vue-leaflet/vue-leaflet'

export default {
  name: 'FormationPopup',
  components: {
    LPopup
  },
  inject: ['mq'],
  props: {
    formation: {
      type: Object,
      required: true
    }
  },
  computed: {
    bothThumbnails () {
      return this.formation.etablissementThumbnail && this.formation.composanteThumbnail
    }
  },
  methods: {
    openFormationDetails () {
      this.$store.dispatch('modals/openFormationDetailsModal', this.formation)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.popup {
  color: $color-cadyco-dark-text;
  font-size: 13px;
  overflow-y: auto;
}

.phone-popup {
  width: 65vw;
  height: 45vh;
}

.header {
  display: flex;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
}

h4 {
  color: $public-bg;
}

.image-container {
  margin-top: 10px;
  position: relative;
  background-color: white;
  display: flex;
  justify-content: space-around;

  .image {
    display: block;
    width: auto;
    height: 50px;
    border-radius: 6px;
    border: 1px solid transparent;
    box-shadow: 0 2px 14px 0 rgba(0,0,0,0.1);
  }

  .separator {
    height: 50px;
    width: 1px;
    background-color: #D9E2EA;
  }
}

</style>
