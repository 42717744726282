<template>
  <div
    class="social-header"
    :class="mq.phone ? 'phone-social-header' : ''"
  >
    <Like
      :post="post"
      @like="updateLike"
    />

    <!-- Expand or collapse comments -->
    <div
      v-if="post.nbComments !== 0"
      class="expand-comments"
      :title="commentLabel"
      @click="toggleComments"
    >
      {{ commentLabel }}
      <img
        v-if="isCommentListDisplayed"
        src="@/assets/arrow_up.png"
        :alt="commentLabel"
      >
      <img
        v-else
        src="@/assets/arrow_down.png"
        :alt="commentLabel"
      >
    </div>
  </div>
</template>

<script>
import Like from '@components/DashBoard/Like'

export default {
  name: 'PostSocialHeader',
  components: { Like },
  inject: ['mq'],
  props: {
    post: {
      type: Object,
      required: true
    },
    isCommentListDisplayed: {
      type: Boolean,
      required: true
    }
  },
  emits: ['like', 'toggleComments'],
  computed: {
    commentLabel () {
      return (this.isCommentListDisplayed ? this.$t('Post.hide') : this.$t('Post.display')) +
        ' ' + (this.post.nbComments) + ' ' +
        (this.post.nbComments > 1 ? this.$t('Post.comments') : this.$t('Post.comment'))
    },
    hasDeletePermission () { // Only if current user are the owner of the comment
      return this.$store.state.auth.user.id === this.comment.userId // TODO find an other way to manage permissions (preferably in back-end)
    },
    hasEditPermission () {
      return this.$store.state.auth.user.id === this.comment.userId
    }
  },
  methods: {
    toggleComments () {
      this.$emit('toggleComments')
    },
    updateLike (value) {
      this.$emit('like', value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@design';

.social-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
/* disable text selection on documents (not convenient when shift-select) */
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none; /* CSS3 (little to no support) */
  color: $like-comment;
  height: 40px;
  padding-right: 20px;

  .expand-comments{
    color: $color-cadyco-dark-text;
    font-weight: bold;
    cursor: pointer;

    img{
      margin-left: 10px;
      margin-right: 10px;
      width: 12px;
      height: 8px;
    }
  }
}

.phone-social-header {
  display: block;
  height: auto;
  padding-right: 0;

  .expand-comments {
    margin: 10px 0 10px 15px;
    text-align: center;
  }
}

</style>
