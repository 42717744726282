import axios from 'axios'
import constants from '@/constants/appConstants'
import { getCookie } from '@/utils/browser.util'

export default {
  createBlog,
  getBlogs,
  modifyBlog,
  deleteBlog,
  likeBlog,
  unlikeBlog,
  commentBlog,
  getComments,
  modifyComment,
  deleteComment
}

const SOCIAL_NETWORK_PATH = '/socialnetwork.socialnetwork'

/**
 * Create BlogEntry
 */
function createBlog (content, attachedFileId, indicatorId, selectedGroupId = -1) {
  const formData = new FormData()
  formData.append('p_auth', getCookie('pauth'))
  formData.append('content', content)
  formData.append('fileEntryId', attachedFileId)
  formData.append('indicatorId', indicatorId)
  formData.append('selectedGroupId', selectedGroupId)
  return axios.post(constants.JSON_WS_URL + SOCIAL_NETWORK_PATH + '/create-blog',
    formData
  ).then(response => response.data)
}

/**
 * get existing Blogs ordered by displayDate
 */
function getBlogs (start, end) {
  return axios.get(constants.JSON_WS_URL + SOCIAL_NETWORK_PATH + '/get-blogs', {
    params: {
      p_auth: getCookie('pauth'),
      start: start,
      end: end
    }
  }).then(response => response.data)
}

/**
 * Modify blog content or attachedFile (only the blog owner can do that)
 */
function modifyBlog (blog, newContent, newAttachedFileId, newIndicatorId = -1) {
  const formData = new FormData()
  formData.append('p_auth', getCookie('pauth'))
  formData.append('blogEntryId', blog.id)
  formData.append('content', newContent)
  formData.append('fileEntryId', newAttachedFileId)
  formData.append('indicatorId', newIndicatorId)
  return axios.post(constants.JSON_WS_URL + SOCIAL_NETWORK_PATH + '/update-blog',
    formData
  ).then(response => response.data)
}

/**
 * Delete blog definitely (Use putBlogToTrash instead if you want a restoration possibility)
 */
function deleteBlog (blog) {
  return axios.get(constants.JSON_WS_URL + SOCIAL_NETWORK_PATH + '/delete-blog', {
    params: {
      p_auth: getCookie('pauth'),
      blogEntryId: blog.id
    }
  }).then(response => response.data)
}

/**
 * Like a blogEntry
 */
function likeBlog (blog) {
  return axios.get(constants.JSON_WS_URL + SOCIAL_NETWORK_PATH + '/like-blog', {
    params: {
      p_auth: getCookie('pauth'),
      blogEntryId: blog.id
    }
  }).then(response => response.data)
}

/**
 * Unlike a blogEntry
 */
function unlikeBlog (blog) {
  return axios.get(constants.JSON_WS_URL + SOCIAL_NETWORK_PATH + '/unlike-blog', {
    params: {
      p_auth: getCookie('pauth'),
      blogEntryId: blog.id
    }
  }).then(response => response.data)
}

/**
 * Add a comment to BlogEntry
 */
function commentBlog (blog, content) {
  const formData = new FormData()
  formData.append('p_auth', getCookie('pauth'))
  formData.append('blogEntryId', blog.id)
  formData.append('content', content)
  return axios.post(constants.JSON_WS_URL + SOCIAL_NETWORK_PATH + '/create-blog-comment',
    formData
  ).then(response => response.data)
}

/**
 * Modify a comment by id
 */
function modifyComment (blog, comment, newContent) {
  const formData = new FormData()
  formData.append('p_auth', getCookie('pauth'))
  formData.append('blogEntryId', blog.id)
  formData.append('commentId', comment.commentId)
  formData.append('content', newContent)
  return axios.post(constants.JSON_WS_URL + SOCIAL_NETWORK_PATH + '/update-blog-comment',
    formData
  ).then(response => response.data)
}

/**
 * Delete a comment by id (Liferay source WS)
 */
function deleteComment (comment) {
  return axios.get(constants.JSON_WS_URL + '/comment.commentmanagerjsonws' + '/delete-comment', {
    params: {
      p_auth: getCookie('pauth'),
      commentId: comment.commentId
    }
  }).then(response => response.data)
}

/**
 * Get ordered Blog's comments (Liferay source WS)
 */
function getComments (blog, start, end, user) {
  return axios.get(constants.JSON_WS_URL + '/comment.commentmanagerjsonws' + '/get-comments', {
    params: {
      p_auth: getCookie('pauth'),
      groupId: user.groupId,
      className: 'com.liferay.blogs.model.BlogsEntry',
      classPK: blog.id,
      start: start,
      end: end
    }
  }).then(response => response.data)
}
