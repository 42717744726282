<template>
  <div
    class="post"
    :class="mq.phone ? 'phone-post' : ''"
  >
    <!-- Post header -->
    <PostHeader
      :post="post"
      @editPost="toggleEditMode"
      @deletePost="deletePost"
    />

    <!-- Post text content -->
    <div
      class="content"
      @keydown.enter.stop="modifyPost"
    >
      <div
        v-if="!isEditMode"
      >
        {{ post.content }}
      </div>
      <Input
        v-else
        ref="modificationInput"
        v-model="modifiedContent"
        class="modification-input"
      />
    </div>

    <!-- Attached files -->
    <div
      v-if="attachedFiles.length > 0"
      class="files"
    >
      <AttachedFiles
        :initial-attached-files="attachedFiles"
        :read-only="!isEditMode"
        class="attached-file"
        @removeAttachedFile="removeAttachedFile"
      />
    </div>

    <!-- Cadyco attached file -->
    <div
      v-if="hasAttachedCadycoFile"
      class="cadyco-attached-file"
    >
      <Map
        v-if="isEditMode ? modifiedCadycoFileData !== undefined : cadycoFileData !== undefined"
        class="media"
        :is-edit-mode="isEditMode"
        :object-list="[isEditMode ? modifiedCadycoFileData : cadycoFileData]"
        @removeFile="removeCadycoFile"
        @addCadycoFileInPortfolio="addCadycoFileInPortfolio"
      />
    </div>

    <!-- Attached indicator -->
    <div
      v-if="(!isEditMode && post.attachedIndicators[0] !== undefined) || (modifiedIndicator !== undefined && modifiedIndicator.indicatorId !== 0)"
      class="attached-indicator"
    >
      <div
        v-if="!isEditMode && post.attachedIndicators[0] !== undefined"
        class="indicator"
      >
        <div
          v-if="post.attachedIndicators[0].error === 'indicatorNotAvailable'"
          class="indicator-not-available"
        >
          {{ $t('router.views.DashBoard.indicatorNotAvailable') }}
        </div>
        <IndicatorItem
          v-else-if="post.attachedIndicators.length !== 0"
          :indicator="post.attachedIndicators[0]"
          :index="0"
          :admin-mode="false"
          :is-in-post="true"
        />
      </div>
      <div v-else-if="isEditMode">
        <IndicatorItem
          v-if="modifiedIndicator !== undefined && modifiedIndicator.indicatorId !== 0"
          :indicator="modifiedIndicator"
          :index="0"
          :admin-mode="true"
          :is-in-post="true"
          @remove="removeIndicator"
        />
      </div>
    </div>

    <!-- Edition mode : add file + cancel/validate buttons -->
    <div
      v-if="isEditMode"
      class="edit-options"
      :class="mq.phone ? 'phone-edit-options' : ''"
    >
      <!-- Add attached file -->
      <div
        v-if="(modifiedAttachedFiles.length === 0) && (modifiedIndicator === undefined || modifiedIndicator.indicatorId === 0)"
        class="add-document"
        @click="openFilePicker"
      >
        <img
          class="picture"
          src="@/assets/socialnetwork_sharedoc.png"
          alt="Share doc"
        >
        <p
          v-t="'CreatePostModal.addPortFolioDocument'"
          class="label"
        />
      </div>

      <div class="edit-buttons">
        <Button
          class="cancel-button"
          :label="$t('Post.cancelPostModifications')"
          @click="cancelModifications"
        />
        <Button
          class="submit-button"
          :label="$t('Post.submitPostModifications')"
          @click="modifyPost"
        />
      </div>
    </div>

    <PostSocial
      v-if="!isEditMode"
      class="social"
      :post="post"
      @like="onLike"
      @commentNb="updateCount"
    />
    <teleport to="body">
      <FilePickerModal
        v-if="isFilePickerDisplayed"
        height="30em"
        :multi-selection="isMultiSelectionAllowed"
        @addedFiles="attachNewFile"
        @close="closeFilePicker"
      />
    </teleport>
  </div>
</template>

<script>
import socialNetwork from '@/api/socialNetwork/socialNetwork.service'
import AttachedFiles from '@components/Base/AttachedFiles'
import media from '@/api/documents/media.service'
import Map from '@components/Map/Map'
import PostHeader from '@components/DashBoard/PostHeader'
import PostSocial from '@components/DashBoard/PostSocial'
import FilePickerModal from '@components/FilePicker/FilePickerModal'
import IndicatorItem from '@components/Indicator/Library/IndicatorItem'

export default {
  name: 'Post',
  components: { IndicatorItem, FilePickerModal, PostSocial, PostHeader, Map, AttachedFiles },
  inject: ['mq'],
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  emits: ['modified', 'deleted'],
  data () {
    return {
      isMultiSelectionAllowed: false,
      cadycoFileData: undefined,
      modifiedContent: '',
      modifiedCadycoFileData: undefined,
      modifiedAttachedFiles: [],
      modifiedIndicator: undefined,
      isEditMode: false,
      isFilePickerDisplayed: false
    }
  },
  computed: {
    hasAttachedCadycoFile () {
      const displayedAttachedFiles = this.isEditMode ? this.modifiedAttachedFiles : this.post.attachedFiles
      for (const attachedFile of displayedAttachedFiles) {
        if (attachedFile.extension === 'cao') {
          return true
        }
      }
      return false
    },
    attachedCadycoFile () {
      // Returns the initial attached cadyco file
      // or in case of editMode, the modified attached cadyco file
      const displayedAttachedFiles = this.isEditMode ? this.modifiedAttachedFiles : this.post.attachedFiles
      for (const attachedFile of displayedAttachedFiles) {
        if (attachedFile.extension === 'cao') {
          return attachedFile
        }
      }
      return undefined
    },
    attachedFiles () {
      // Returns the initial attached files
      // or in case of editMode, the modified attached files
      const attachedFiles = []
      const displayedAttachedFiles = this.isEditMode ? this.modifiedAttachedFiles : this.post.attachedFiles
      for (const attachedFile of displayedAttachedFiles) {
        if (attachedFile.extension !== 'cao') {
          attachedFiles.push(attachedFile)
        }
      }
      return attachedFiles
    }
  },
  watch: {
    'post.modifiedDate' () { // reload cadyco file if post was modified
      if (this.hasAttachedCadycoFile) {
        this.loadCadycoFileData()
      } else {
        this.cadycoFileData = undefined
      }
    }
  },
  created () {
    if (this.hasAttachedCadycoFile) {
      this.loadCadycoFileData() // TODO load only if post is in the viewport?
    }
  },
  methods: {
    setEditMode () {
      this.modifiedContent = this.post.content
      this.modifiedAttachedFiles = this.post.attachedFiles
      this.modifiedIndicator = this.post.attachedIndicators[0]
      this.modifiedCadycoFileData = this.cadycoFileData
      this.isEditMode = true
    },
    addCadycoFileInPortfolio () {
      if (this.post.attachedFiles[0].extension === 'cao') {
        media.addCadycoFileToPortfolio(this.post.attachedFiles[0].id).then((data) => {
          if (data.success) {
            this.$store.dispatch('popups/pushPopup', { message: this.$t('Post.addFileToPortfolioPopup') })
          } else {
            this.dispatch('popups/pushPopup', { message: 'ERROR' })
          }
        })
      }
    },
    removeAttachedFile (removedAttachedFile) {
      const newModifiedAttachedFiles = []
      for (const attachedFile of this.modifiedAttachedFiles) {
        if (attachedFile.extension !== 'cao' && attachedFile.fileEntryId !== removedAttachedFile.fileEntryId) {
          newModifiedAttachedFiles.push(attachedFile)
        }
      }
      this.modifiedAttachedFiles = newModifiedAttachedFiles
    },
    removeCadycoFile () {
      const newModifiedAttachedFiles = []
      for (const attachedFile of this.modifiedAttachedFiles) {
        if (attachedFile.extension !== 'cao') {
          newModifiedAttachedFiles.push(attachedFile)
        }
      }
      this.modifiedAttachedFiles = newModifiedAttachedFiles
    },
    removeIndicator () {
      this.modifiedIndicator = { indicatorId: 0 } // id 0 is to remove file on modifyBlog() method
    },
    attachNewFile (selectedFiles) {
      const newAttachedFile = selectedFiles[0]

      this.modifiedAttachedFiles = [newAttachedFile]
      if (newAttachedFile.extension === 'cao') {
        media.getCadycoFileData(newAttachedFile.id).then((data) => {
          if (data.success) {
            this.modifiedCadycoFileData = data.cadycoFileData
          } else {
            console.error('Sorry but the cadyco linked file to this blog doesn\'t exist anymore')
          }
        })
      }
    },
    attachNewIndicator () {
      // TODO (or not?)
    },
    cancelModifications () {
      this.isEditMode = false
    },
    modifyPost () {
      let modifiedAttachedFileId = 0
      for (const modifiedAttachedFile of this.modifiedAttachedFiles) {
        modifiedAttachedFileId = modifiedAttachedFile.id
      }

      let modifiedIndicatorId
      this.modifiedIndicator === undefined ? modifiedIndicatorId = -1 : modifiedIndicatorId = this.modifiedIndicator.indicatorId // -1 means don't update indicator

      socialNetwork.modifyBlog(this.post, this.modifiedContent, modifiedAttachedFileId, modifiedIndicatorId).then((data) => {
        if (data.success) {
          data.modifiedBlog.attachedFiles.forEach(function (file) { file.type = 'File' })
          this.$emit('modified', data.modifiedBlog)
          this.$store.dispatch('popups/pushPopup', { message: this.$t('Post.modifiedPostPopup') })
        } else {
          console.error('error while modifying blogs: ' + data.error)
        }
        this.toggleEditMode()
      })
    },
    toggleEditMode () {
      if (this.isEditMode === true) {
        this.cancelModifications()
      } else {
        this.setEditMode()
      }
    },
    deletePost () {
      socialNetwork.deleteBlog(this.post).then((data) => {
        if (data.success) {
          this.$emit('deleted')
          this.$store.dispatch('popups/pushPopup', { message: this.$t('Post.deletedPostPopup') })
        } else {
          console.error('error while deleting blogs: ' + data.error)
        }
      })
    },
    loadCadycoFileData () {
      media.getCadycoFileData(this.post.attachedFiles[0].id).then((data) => { // assume this.post.attachedFiles[0] is a cadyco file
        if (data.success) {
          this.cadycoFileData = data.cadycoFileData
        } else {
          console.error('Sorry but the cadyco linked file to this blog doesn\'t exist anymore')
        }
      })
    },
    onLike (liked) {
      if (liked) {
        socialNetwork.likeBlog(this.post).then((data) => {
          if (data.success) {
            const copy = JSON.parse(JSON.stringify(this.post))
            copy.isLiked = true
            copy.nbLikes += 1
            this.$emit('modified', copy)
          } else {
            console.error('Unable to like post ' + this.post.id)
          }
        })
      } else {
        socialNetwork.unlikeBlog(this.post).then((data) => {
          if (data.success) {
            const copy = JSON.parse(JSON.stringify(this.post))
            copy.isLiked = false
            copy.nbLikes -= 1
            this.$emit('modified', copy)
          } else {
            console.error('Unable to unlike post ' + this.post.id)
          }
        })
      }
    },
    updateCount (nb) {
      const copy = JSON.parse(JSON.stringify(this.post))
      // this.post.nbComments
      copy.nbComments = nb
      this.$emit('modified', copy)
    },
    openFilePicker () {
      this.isFilePickerDisplayed = true
    },
    closeFilePicker () {
      this.isFilePickerDisplayed = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@design';

.post{
  position: relative;
  min-height: 10em;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.08);

  .content {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 8px 0;

    .modification-input{
      margin-top: 10px;
    }
  }

  .files {
    display: flex;
    align-items: center;

    .attached-file{
      width: 100%;
      margin-left: 15px
    }
  }

  .cadyco-attached-file {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .media {
      width: 500px;
      height: 400px;
    }

    .remove-media {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 25px;
      cursor: pointer;
    }
  }

  .attached-indicator {
    display: flex;
    max-width: 100px;
    margin-left: 40px;
    margin-top: 25px;

    .indicator-not-available {
      margin-left: 20px;
      height: 40px;
      display: flex;
      color: #ff4a4a;
      align-items: center;
    }
  }

  .social {
    margin-top: 5px;
  }

  .edit-options {
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 20px;
    height: 50px;

    .add-document {
      display: flex;
      align-items: center;
      margin-left: 20px;
      cursor: pointer;

      .picture {
        max-width: 20px;
        max-height: 20px;
        margin-right: 10px;
      }

    }

    .add-document:hover {
      color: $color-dark-text;
    }

    .edit-buttons {
      display: flex;
      margin-left: auto;
      margin-top: 5px;
      margin-right: 5px;

      .cancel-button {
        margin-right: 10px;
      }
    }
  }

  .phone-edit-options {
    display: block;
    height: auto;
    margin-bottom: 10px;
    margin-right: 0;

    .edit-buttons {
      width: 100%;
      justify-content: center;
    }
  }
}

.phone-post {
  width: 90vw;
}

</style>
