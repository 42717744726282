<template>
  <AuthenticatedLayout>
    <div
      ref="scrollView"
      class="dashboard"
      :class="mq.phone ? 'phone-dashboard' : ''"
      @scroll="handleScroll"
    >
      <div class="dashboard-content">
        <DashBoardCreatePost
          class="new-post"
          @postCreated="postCreated"
        />
        <div class="post-list">
          <Post
            v-for="post in loadedPosts"
            :key="post.id"
            class="dashboard-post"
            :post="post"
            @modified="postModified"
            @deleted="postDeleted(post)"
          />
        </div>
      </div>
    </div>
  </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/router/layouts/AuthenticatedLayout.vue'
import socialNetwork from '@/api/socialNetwork/socialNetwork.service'
import appConstants from '@/constants/appConstants'
import Post from '@components/DashBoard/Post'
import DashBoardCreatePost from '@components/DashBoard/DashBoardCreatePost'

export default {
  name: 'Dashboard',
  components: {
    DashBoardCreatePost,
    Post,
    AuthenticatedLayout
  },
  inject: ['mq'],
  data () {
    return {
      loadedPosts: [],
      scrollLockPosition: -1,
      isLoadingPosts: false
    }
  },
  computed: {
    isAContextMenuDisplayed () {
      return this.$store.state.contextMenu.isAContextMenuDisplayed
    }
  },
  watch: {
    'isAContextMenuDisplayed' () { // Lock scroll if a context menu is displayed (improvement: make the context menu move with the scroll)
      if (this.isAContextMenuDisplayed) {
        this.scrollLockPosition = this.$refs.scrollView.scrollTop
      } else {
        this.scrollLockPosition = -1
      }
    }
  },
  created () {
    this.loadPosts()
  },
  methods: {
    loadPosts () {
      this.isLoadingPosts = true
      socialNetwork.getBlogs(this.loadedPosts.length, this.loadedPosts.length + appConstants.nbPostToLoadAtOneTime).then((data) => {
        this.isLoadingPosts = false
        if (data.success) {
          this.loadedPosts = [...this.loadedPosts, ...data.blogEntryList]
          this.loadedPosts.forEach(function (post) {
            post.attachedFiles.forEach(function (file) { file.type = 'File' })
          })
        } else {
          console.error('error while getting blogs')
        }
      })
    },
    postCreated (newPost) {
      // Put the new Post in first position
      this.loadedPosts.unshift(newPost)
    },
    postModified (modifiedPost) {
      for (let i = 0; i < this.loadedPosts.length; ++i) {
        if (this.loadedPosts[i].id === modifiedPost.id) {
          this.loadedPosts.splice(i, 1, modifiedPost) // delete old and insert new post (to trigger reactivity)
          break
        }
      }
    },
    postDeleted (deletedPost) {
      // remove post from list or reload post list? (how to handle start and end in last case?)
      for (let i = 0; i < this.loadedPosts.length; ++i) {
        if (this.loadedPosts[i].id === deletedPost.id) {
          this.loadedPosts.splice(i, 1)
          break
        }
      }
    },
    handleScroll () {
      const scrollView = this.$refs.scrollView
      const nbPixelsBeforeBottom = scrollView.scrollHeight - (scrollView.scrollTop + scrollView.clientHeight)

      if (this.scrollLockPosition !== -1) {
        this.$nextTick(() => {
          scrollView.scrollTop = this.scrollLockPosition
        })
      }

      if (nbPixelsBeforeBottom <= 100 && nbPixelsBeforeBottom !== 0 && !this.isLoadingPosts) { // avoid useless requests when user scroll at the bottom of the page // todo: avoid loading on the scroll between the pixel 100 and the 0?
        this.loadPosts()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.dashboard {
  background-color: $dark-white-bg;
  height: calc(100% - #{$desktop-search-bar-height});
  overflow-y: scroll;

  .dashboard-content {
    min-width: 400px;
    max-width: 1200px;
    margin: auto;
    width: 60%;
    display: flex;
    flex-direction: column;

    .new-post {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .post-list {
      width: 100%;
      height: 100%;
      position: relative;
      margin: auto;

      .dashboard-post {
        margin-bottom: 15px;
      }
    }
  }
}

.phone-dashboard {
  height: calc(100% - #{$mobile-search-bar-height});

  .dashboard-content {
    align-items: center;
    width: 100vw;
    min-width: 0;
    max-width: 1200px;
  }

  .post-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

</style>
