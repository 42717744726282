<template>
  <l-popup
    class="popup"
    :class="mq.phone ? 'phone-popup' : ''"
  >
    <div
      class="header"
      @click="openEtablissementDetails"
    >
      <h4>
        {{ etablissement.Acronyme }}
      </h4>
      <img
        class="icon details-icon"
        src="@assets/arrow_right_blue.svg"
        alt="Details"
      >
    </div>
    <p> Description de l'établissement </p>
    <span> Nom : {{ etablissement.Nom }} </span><br>
    <span> Lien de l'établissement : <a
      :href="etablissement.SiteWeb"
      target="_blank"
    > {{ etablissement.SiteWeb }} </a> </span><br>
    <div
      class="image-container"
    >
      <img
        v-if="etablissement.thumbnail"
        class="image"
        :src="etablissement.thumbnail"
        alt="img"
      >
    </div>
  </l-popup>
</template>

<script>
import { LPopup } from '@vue-leaflet/vue-leaflet'

export default {
  name: 'EtablissementPopup',
  components: {
    LPopup
  },
  inject: ['mq'],
  props: {
    etablissement: {
      type: Object,
      required: true
    }
  },
  methods: {
    openEtablissementDetails () {
      this.$store.dispatch('modals/openEtablissementDetailsModal', { etablissement: this.etablissement })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.popup {
  color: $color-cadyco-dark-text;
  font-size: 13px;
  overflow-y: auto;
}

.phone-popup {
  width: 65vw;
  height: 45vh;
}

.header {
  display: flex;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
}

.image-container {
  position: relative;
  text-align: center;
  background-color: white;

  .image {
    display: block;
    width: 80px;
    height: 50px;
    border-radius: 6px;
    border: 1px solid transparent;
    box-shadow: 0 2px 14px 0 rgba(0,0,0,0.1);
  }
}

h4 {
  color: $public-bg;
}

</style>
