<template>
  <div class="public-indicators-container">
    <div
      class="public-indicators"
      :class="[device + '-public-indicators', menuView ? 'menu-view' : 'map-view']"
    >
      <!-- Left side : the menu-->
      <div
        class="menu"
        :class="device + '-menu'"
      >
        <p
          v-for="indicator in publicIndicators"
          :key="indicator.title"
          class="menu-item"
          :class="{'selected': selectedIndicator.title === indicator.title}"
          @click="selectMenuItem(indicator)"
        >
          {{ indicator.title }}
        </p>

        <Transition
          v-if="!isCadycoModalDisplayed && mq.phone"
          name="fade"
        >
          <div
            v-if="menuView"
            class="right-arrow"
            @click="menuView=false"
          >
            <img
              src="@assets/arrow_right.svg"
              alt="right-arrow"
            >
          </div>
        </Transition>
      </div>

      <!-- Right side : the map of formations -->
      <div
        v-if="selectedIndicator && selectedIndicator.isMap"
        class="map"
        :class="device + '-map'"
      >
        <Transition
          v-if="!isCadycoModalDisplayed"
          name="fade"
        >
          <div
            v-if="!menuView"
            class="back-arrow"
            @click="menuView=true"
          >
            <img
              src="@assets/arrow_white_left.png"
              alt="back-arrow"
            >
          </div>
        </Transition>
        <l-map
          :zoom="zoom"
          :max-zoom="19"
          :use-global-leaflet="true"
          :center="center"
          :options="mapOptions"
          @update:center="centerUpdate"
          @update:zoom="zoomUpdate"
        >
          <LCustomMarkerCluster>
            <l-tile-layer
              :url="url"
              :attribution="attribution"
            />
            <l-marker
              v-for="formation in formationList"
              :key="formation.id"
              :lat-lng="latitudeLongitude(formation.GeoLocalisation)"
            >
              <FormationPopup
                :formation="formation"
              />
            </l-marker>
          </LCustomMarkerCluster>
        </l-map>
      </div>

      <!-- Public indicator -->
      <div
        v-else-if="selectedIndicator"
        class="indicator"
        :class="device + '-indicator'"
      >
        <div
          v-if="!menuView"
          class="back-arrow"
          @click="menuView=true"
        >
          <img
            src="@assets/arrow_white_left.png"
            alt="back-arrow"
          >
        </div>
        <iframe
          :src="'shiny/' + selectedIndicator.localUrl + '?indicatorId=' + selectedIndicator.indicatorId + '&canCustom=true'"
          class="indicator-iframe"
          frameborder="0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import { Icon, latLng } from 'leaflet'
import { LMap, LMarker, LTileLayer } from '@vue-leaflet/vue-leaflet'
import formationService from '@/api/formation/formation.service'
import libraryService from '@/api/indicator/library.service'
import FormationPopup from '@components/Map/FormationPopup'
import LCustomMarkerCluster from '@components/Map/LCustomMarkerCluster'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default {
  components: {
    LCustomMarkerCluster,
    FormationPopup,
    LMap,
    LTileLayer,
    LMarker
  },
  inject: ['mq'],
  props: {
    msg: {
      type: String,
      default: ''
    },
    map: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      menuView: !this.mq.phone,
      formationList: [],
      publicIndicators: [],
      selectedIndicator: undefined,
      zoom: 8,
      center: latLng(46.35312518717163, 6.784678858852737),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      mapOptions: {
        zoomControl: !this.mq.phone,
        zoomSnap: 0.5
      }
    }
  },
  computed: {
    device () {
      if (this.mq.phone) {
        return 'phone'
      } else if (this.mq.projectSizeLimit1) {
        return 'limit1'
      } else if (this.mq.projectSizeLimit2 || this.mq.tablet) {
        return 'limit2'
      } else {
        return 'desktop'
      }
    },
    isCadycoModalDisplayed () { // Because the menu Arrow have a z-index > to the modal z-index, not display it if a modal is active
      return this.$store.state.modals.isEtablissementDetailsModalDisplayed || this.$store.state.modals.isFormationDetailsModalDisplayed
    },
    profileCatalog () {
      if (this.$store.state.catalog.profileList === undefined) {
        this.$store.dispatch('catalog/initProfileList')
      }
      if (this.$store.state.catalog.profileList !== undefined) {
        return this.$store.state.catalog.profileCatalog
      } else {
        return undefined
      }
    }
  },
  created () {
    libraryService.getPublicIndicators('').then((data) => {
      if (data.success) {
        let find = false

        for (const idx in data.publicIndicators) {
          const publicIndicator = data.publicIndicators[idx]
          if (publicIndicator.title === 'Carte des formations') { // Todo: find a better way to identify it
            this.publicIndicators.unshift(publicIndicator) // Put the map at the index 0, so it will display on top of the list
            find = true
          } else {
            if (!publicIndicator.isHidden) { // Not display indicator in maintenance
              this.publicIndicators.push(publicIndicator)
            }
          }
        }

        // Handle Map indicator
        if (!find) {
          this.getFormations()
          this.publicIndicators.unshift({ title: 'Formations', isMap: true })
        }
      }

      this.selectedIndicator = this.publicIndicators[0]
    })
  },
  methods: {
    getFormations () {
      formationService.getFormations('').then((data) => {
        if (data.success) {
          this.formationList = data.formations
        }
      })
    },
    selectMenuItem (indicator) {
      this.selectedIndicator = indicator
      if (this.mq.phone) {
        this.menuView = false
      }
    },
    latitudeLongitude ({ Latitude, Longitude }) {
      return latLng(Latitude, Longitude)
    },
    zoomUpdate (zoom) {
      this.currentZoom = zoom
    },
    centerUpdate (center) {
      this.currentCenter = center
    },
    showLongText () {
      this.showParagraph = !this.showParagraph
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@design";

.public-indicators-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.phone-public-indicators {
  position: absolute;
  transition: left 1s ease 0s;

  &.menu-view {
    left:0;
  }

  &.map-view {
    left:-100%;
  }
}

.public-indicators {
  display: flex;
  width: 100%;
  height: 100%;

  .menu {
    position: relative;
    height: 800px;
    width: 320px;
    background-color: #0B3C5F;
    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.1);

    p {
      display: flex;
      align-items: center;
      justify-content: left;
      min-height: 48px;
      width: 82%;
      color: #FFFFFF;
      font-size: 1rem;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 27px;
      padding-left: 15px;
      text-align: left;
      cursor: pointer;

      &.selected {
        color: #27AAE1;
      }
    }

    .right-arrow {
      position: absolute;
      cursor: pointer;
      top: 20px;
      right: 0;
      height: 35px;
      width: 34px;
      border-radius: 6px 0 0 6px;
      background-color: white;
      box-shadow: 0 2px 14px 0 rgba(0,0,0,0.1);
      display: flex;
      align-items: center;
      justify-content: center;

      img{
        width: 10px;
      }
    }
  }

  .limit2-menu, .phone-menu {
    width: 250px;
    min-width: 250px;

    p {
      width: 100%;
      padding-left: 15px;
      text-align: left;
      justify-content: left;
    }
  }

  .phone-menu {
    width: 100%;
    height: 452px;
    min-width: 100%;
  }

  .map {
    position: relative;
    flex: 1;
    min-width: 400px;
    min-height: 400px;
    height: 800px;

    //.fade-enter-active, .fade-leave-active {
    //  transition: opacity 1s;
    //}
    //.fade-enter, .fade-leave-to {
    //  opacity: 0;
    //}
  }

  .back-arrow {
    position: absolute;
    z-index: 500;
    cursor: pointer;
    top: 20px;
    height: 35px;
    width: 34px;
    border-radius: 0 6px 6px 0;
    background-color: $color-menu-bg;
    box-shadow: 0 2px 14px 0 rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
    justify-content: center;

    img{
      width: 10px;
    }
  }

  .phone-map {
    width: 100%;
    min-width: 100%;
    height: 452px;
  }

  .indicator {
    position: relative;
    flex: 1;
    min-width: 400px;
    min-height: 400px;
    height: 800px;
  }

  .indicator-iframe {
    position: relative;
    flex: 1;
    min-width: 100%;
    min-height: 400px;
    height: 800px;
  }

  .phone-indicator {
    width: 100%;
    min-width: 100%;
    height: 452px;

    .indicator-iframe {
      height: 452px;
    }
  }
}

</style>
