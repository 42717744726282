<template>
  <div
    class="comment"
    :class="mq.phone ? 'phone-comment' : ''"
    @mouseover="isHovering = true"
    @mouseleave="isHovering = false"
  >
    <div class="user-picture-column">
      <img
        v-if="userPicture"
        class="picture"
        :src="userPicture"
        alt="Avatar"
      >
      <img
        v-else
        src="@/assets/default_avatar.png"
        alt="Avatar"
      >
    </div>
    <div class="second-column">
      <div class="comment-header">
        <div class="user-name">
          {{ comment.userName }}
        </div>
        <!--<div class="creation-date">
          {{ formattedCreationDate }}
        </div>-->
        <div
          v-if="isModified"
          v-t="'Comment.modified'"
          class="is-modified"
        />

        <!-- Actions : edit/delete comment if allowed -->
        <div
          v-if="isHovering && hasEditPermission"
          class="comment-actions"
        >
          <BaseIcon
            v-if="hasEditPermission && !isEditMode"
            name="pencil-alt"
            class="comment-action"
            @click="setEditMode"
          />
          <BaseIcon
            v-if="hasDeletePermission"
            name="trash-alt"
            class="comment-action"
            @click="confirmRemoveComment"
          />
        </div>
      </div>

      <!-- Comment body -->
      <div
        v-if="!isEditMode"
        class="comment-body"
      >
        {{ comment.body }}
      </div>
      <div
        v-else
        class="content-edition"
        @keydown.exact.enter.stop="editComment"
      >
        <Input
          ref="modificationInput"
          v-model="modifiedContent"
          class="modification-input"
        />
        <Button
          class="submit-button"
          :label="$t('Comment.confirmModification')"
          @click="editComment"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import BaseIcon from '@components/Base/BaseIcon'
import socialNetwork from '@/api/socialNetwork/socialNetwork.service'
import userInfosService from '@/api/userInfos/userInfos.service'

export default {
  name: 'Comment',
  components: { BaseIcon },
  inject: ['mq'],
  props: {
    comment: {
      type: Object,
      required: true
    },
    parentPost: {
      type: Object,
      required: true
    }
  },
  emits: ['modified', 'deleted'],
  data () {
    return {
      isContextMenuDisplayed: false,
      isHovering: false,
      isEditMode: false,
      modifiedContent: '',
      userPicture: undefined
    }
  },
  computed: {
    isModified () {
      return this.comment.createDate !== this.comment.modifiedDate
    },
    formattedCreationDate () {
      return moment(this.comment.createDate).calendar()
    },
    hasDeletePermission () { // Only if current user are the owner of the comment
      return this.$store.state.auth.user.id === this.comment.userId // TODO find an other way to manage permissions (preferably in back-end)
    },
    hasEditPermission () {
      return this.$store.state.auth.user.id === this.comment.userId
    }
  },
  created () {
    userInfosService.getUserPicture(this.comment.userId).then((data) => {
      if (data.success) {
        this.userPicture = data.userPicture
      } else {
        console.error('Error while getting userThumnail', data.error)
      }
    })
  },
  methods: {
    setEditMode () {
      this.modifiedContent = this.comment.body
      this.isEditMode = true
    },
    focusInput () {
      this.$refs.modificationInput.$el.firstChild.focus()
    },
    editComment () {
      if (this.modifiedContent.length === 0) {
        this.deleteComment()
      } else {
        socialNetwork.modifyComment(this.parentPost, this.comment, this.modifiedContent).then((data) => {
          if (data.success) {
            // manually change comment instead of use back-end response (should be data.updatedComment) because of comment json parsing (difficult to unify)
            const newComment = { ...this.comment }
            newComment.body = this.modifiedContent
            newComment.modifiedDate = moment().valueOf()
            this.$emit('modified', newComment)
            this.$store.dispatch('popups/pushPopup', { message: this.$t('Post.modifiedCommentPopup') })
            this.isEditMode = false
          }
        })
      }
    },
    confirmRemoveComment () {
      this.$store.dispatch('warningModal/addWarning', {
        text: this.$t('router.views.Documents.Warning.deleteDocument'),
        lastAction: { fct: this.deleteComment, params: [this.comment] }
      })
    },
    deleteComment () {
      socialNetwork.deleteComment(this.comment).then(() => {
        this.$emit('deleted')
        this.$store.dispatch('popups/pushPopup', { message: this.$t('Post.deletedCommentPopup') })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@design';

.comment{
  display: flex;

  .user-picture-column {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    margin-right: 20px;

    .picture{
      border-radius: 50px;
      width: 38px;
      height: 38px;
    }
  }

  .second-column {

    .comment-header {
      display: flex;
      width: 100%;
      align-items: center;
      color: $color-sweet-text;
      font-size: 15px;
      margin-bottom: 5px;
      margin-right: 10px;

      .user-name{
        color: $color-cadyco-dark-text;
        font-weight: bold;
        margin-right: 15px;
      }

      .creation-date {
        margin-right: 15px;
      }

      .comment-actions {
        cursor: pointer;
        margin-left: 20px;
        margin-right: 15px;
        display: flex;
        justify-content: center;
        width: 25px;

        .comment-action {
          margin-right: 10px;
        }
      }

      .actions-container:hover {
        color: $color-dark-text;
      }
    }

    .comment-body {
      width: 100%;
    }

    .content-edition {
      display: flex;

      .modification-input {
        width: 100%;
      }
      .submit-button {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }
}

.phone-comment {
  .user-picture-column {
    margin-right: 10px;
    .picture {
      margin-top:3px;
      border-radius: 15px;
      width: 30px;
      height: 30px;
    }
  }

  .second-column {

    .comment-header {
font-size: 1em;
    }
  }
}

</style>
